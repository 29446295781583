import React, { useRef } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import { Button } from '@hurtigruten/design-system-components';

import { ContentfulImage } from '@atoms';
import { useLocale, useMediaQuery, useElementSize } from '@hooks';
import { breakpoints } from '@src/utils';
import { MegaNavItem, B2cExpeditionPromoBanner } from '@content/gql/graphql';
import { useSiteData } from '@context/DataContext';

import ContactUsContent from './ContactUsContent';
import { SectionNames } from './constants';

export const hideNorwayForNordics = (
  locale: TLocale,
  array: MegaNavItem['links']
) => {
  if (['nb-no', 'da-dk', 'sv-se'].includes(locale)) {
    return array.filter((x) => x.label !== 'Norway');
  }
  return array;
};

export const MainLinks = ({
  links,
  navControl,
  activeTab,
  animateClass,
  hoverClass,
  onClick,
  locale,
  tabFocus
}: {
  links: MegaNavItem['links'];
  navControl: string;
  activeTab: string | null;
  animateClass: string;
  hoverClass: string;
  onClick: () => void;
  locale: TLocale;
  tabFocus: boolean;
}) => {
  const filterLinks =
    navControl === SectionNames.Destinations
      ? hideNorwayForNordics(locale, links)
      : links;
  return (
    <div
      className={clsx('flex flex-col  flex-wrap max-h-48', {
        'flex-nowrap': navControl === SectionNames.Ships
      })}
    >
      {filterLinks.map((a, i) => {
        // if destinations outputs 0-4 until loop is finished
        const counter = navControl === SectionNames.Destinations ? i % 5 : i;
        const delay = activeTab === navControl ? 5 : 1;
        return (
          <div
            key={i}
            className={`tabletLandscape:w-1/3 tabletLandscape:pr-6 ${animateClass}`}
            style={{
              transitionDelay: `${(counter + 2) * delay}0ms`
            }}
          >
            <NextLink
              locale={locale}
              href={a.url}
              prefetch={false}
              className={hoverClass}
              onClick={onClick}
              tabIndex={tabFocus ? 0 : -1}
            >
              {a.label}
            </NextLink>
          </div>
        );
      })}
    </div>
  );
};

export const FeaturedLinks = ({
  links,
  heading,
  navControl,
  animateClass,
  hoverClass,
  activeTab,
  onClick,
  locale,
  tabFocus
}: {
  links: MegaNavItem['featuredLinks'];
  heading: MegaNavItem['featuredLinksHeading'];
  navControl: string;
  animateClass: string;
  hoverClass: string;
  activeTab: string | null;
  onClick: () => void;
  locale: TLocale;
  tabFocus: boolean;
}) => {
  const delay = activeTab === navControl ? 5 : 1;
  return (
    <div className="flex flex-col mt-9">
      {heading && (
        <p
          className={`block mb-2 text-2xs delay-[30ms] text-hx-neutrals-60 ${animateClass}`}
          style={{ transitionDelay: `${5 * delay}0ms` }}
        >
          {heading}
        </p>
      )}
      {links.map((a, i) => (
        <div
          key={i}
          className={animateClass}
          style={{ transitionDelay: `${(i + 5) * delay}0ms` }}
        >
          <NextLink
            locale={locale}
            href={a.url}
            prefetch={false}
            className={`block mb-4 text-sm ${hoverClass}`}
            onClick={onClick}
            tabIndex={tabFocus ? 0 : -1}
          >
            {a.label}
          </NextLink>
        </div>
      ))}
    </div>
  );
};

const FeaturedImage = ({
  featuredImage,
  sectionName,
  activeTab,
  isLaptop,
  imageHeight
}: {
  featuredImage: MegaNavItem['featuredImage'];
  sectionName: string;
  activeTab: string | null;
  isLaptop: boolean;
  imageHeight: number;
}) => (
  <div
    className={clsx(
      `laptop:w-[447px] tabletLandscape:w-[333px] h-full relative overflow-hidden`
    )}
  >
    <div
      className={clsx(
        `laptop:w-[447px] tabletLandscape:w-[333px] opacity-0 overflow-hidden h-full absolute duration-200 transition delay-100 ease-in-out-smooth`,
        {
          'opacity-100': activeTab === sectionName
        }
      )}
    >
      <ContentfulImage
        alt={featuredImage?.alt}
        className={``}
        src={featuredImage?.url}
        width={isLaptop ? 447 : 333}
        objectFit="cover"
        height={imageHeight}
      />
    </div>
  </div>
);

export const SpotLight = ({
  tab,
  navControl,
  animateClass,
  hoverClass,
  locale,
  activeTab,
  newsletterPromotion,
  tabFocus,
  onClick,
  windowHeight
}: {
  tab: MegaNavItem['spotlight'];
  navControl: string;
  animateClass: string;
  hoverClass: string;
  locale: TLocale;
  activeTab: string | null;
  newsletterPromotion?: B2cExpeditionPromoBanner | null;
  tabFocus: boolean;
  onClick: () => void;
  windowHeight: number;
}) => (
  <div
    className={clsx(` ${tab?.isNewsletter ? 'w-[322px]' : 'w-[207px]'} `, {
      'mt-auto': windowHeight >= 940,
      'mt-14': windowHeight < 940
    })}
  >
    {tab?.title && (
      <p
        className={`block w-full !mb-2  text-2xs text-hx-neutrals-60 ${animateClass}  ${
          activeTab === navControl ? 'delay-[450ms]' : 'delay-100'
        }`}
      >
        {tab.title}
      </p>
    )}
    {tab?.subtitle && tab?.url && (
      <div
        className={` ${
          activeTab === navControl ? 'delay-500' : 'delay-100'
        } ${animateClass}`}
      >
        <NextLink
          locale={locale}
          href={`${tab?.url.url}`}
          prefetch={false}
          className={`block w-full  delay-100 ${hoverClass}`}
          tabIndex={tabFocus ? 0 : -1}
          onClick={onClick}
        >
          {tab.subtitle}
        </NextLink>
      </div>
    )}
    {tab?.subtitle && !tab?.url && (
      <div
        className={` ${
          activeTab === navControl ? 'delay-500' : 'delay-100'
        } ${animateClass}`}
      >
        <p className={`block w-full  `}>{tab.subtitle}</p>
      </div>
    )}
    <div
      className={clsx(`w-[207px] overflow-hidden relative`, {
        'h-[116px] ': !tab?.isNewsletter,
        'h-[56px] ': tab?.isNewsletter
      })}
    >
      <div
        className={clsx(
          `w-[207px] overflow-hidden h-full absolute opacity-0 transition duration-[250ms] ease-in-out-smooth bg-white`,
          `${activeTab === navControl ? 'delay-[550ms]' : 'delay-100'} `,
          {
            'opacity-100': activeTab === navControl
          }
        )}
      >
        {tab?.isNewsletter && newsletterPromotion?.cta?.slug && (
          <Button
            appearance="primary"
            fillMobileWidth={false}
            tabIndex={tabFocus ? 0 : -1}
            href={`/${locale}${newsletterPromotion?.cta?.slug || '/'}`}
            text={newsletterPromotion?.cta?.label}
            onClick={onClick}
          />
        )}
        {tab?.image && !tab?.isNewsletter && tab?.url && (
          <NextLink
            locale={locale}
            href={`${tab?.url.url}`}
            prefetch={false}
            className={`block w-full`}
            tabIndex={tabFocus ? 0 : -1}
            onClick={onClick}
          >
            <ContentfulImage
              alt={tab.image?.alt}
              className={``}
              src={tab.image?.url}
              width={'207'}
              objectFit="cover"
              height={116}
            />
          </NextLink>
        )}
        {tab?.image && !tab?.isNewsletter && !tab?.url && (
          <ContentfulImage
            alt={tab.image?.alt}
            className={``}
            src={tab.image?.url}
            width={207}
            objectFit="cover"
            height={116}
          />
        )}
      </div>
    </div>
  </div>
);

const MegaNavPanelDesktop = ({
  sections,
  onClick,
  activeTab,
  headerHeight
}: {
  sections: MegaNavItem[];
  onClick: (arg: string) => void;
  activeTab: string | null;
  headerHeight: number;
}) => {
  const locale = useLocale();
  const { data: siteData } = useSiteData();
  const { footer: footerData } = siteData;
  const isTabletL = useMediaQuery(breakpoints.tabletLandscape);
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const windowHeight = window.innerHeight;
  const panelContainerRef = useRef<HTMLDivElement>(null);
  const { height: panelHeight } = useElementSize(panelContainerRef);
  const panelBottomOffset = headerHeight + 80;

  const { newsletterPromotion } = footerData ?? {
    newsletterPromotion: null
  };

  const animateClass = (tab: string) =>
    clsx(
      ` block mb-3 text-sm opacity-0 duration-[250ms] transition ease-in-out-smooth relative `,
      {
        'opacity-100  z-10 delay-0 duration-[100ms]': activeTab === tab
      }
    );

  const hoverClass = () =>
    clsx(
      'inline bg-gradient-to-r from-black to-black bg-leftOffBottom bg-no-repeat bg-[length:0%_1px] w-fit duration-200 hover:bg-[length:100%_1px] transition-bgsize'
    );

  const showPanel = () => {
    if (
      activeTab !== null &&
      activeTab !== SectionNames.Account &&
      activeTab !== SectionNames.CountrySelect
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      data-testid="MegaNavPanel"
      ref={panelContainerRef}
      className={clsx(
        'h-0 overflow-hidden w-full absolute bg-white transition-all duration-100 ease-in-out-smooth z-[101]',
        {
          'h-[629px]': showPanel() && windowHeight >= 940
        }
      )}
      style={{
        height:
          showPanel() && windowHeight < 940
            ? `calc(100vh - ${panelBottomOffset}px`
            : ''
      }}
      aria-hidden={showPanel() ? 'false' : 'true'}
    >
      {sections.map((tab, i) => (
        <div
          key={i}
          className={clsx('absolute top-0 left-0 w-full h-full flex', {
            '!z-100 ': activeTab === tab.navControl
          })}
          id={`nav-${tab.navControl
            .replace(/[^A-Za-z0-9]/g, '')
            .toLowerCase()}`}
        >
          <div
            className={clsx(
              'flex flex-col flex-1 w-auto h-full overflow-x-scroll px-20 desktop:px-40 tabletLandscape:py-12 ',
              {
                'scrollbar mr-1': isTabletL && windowHeight < 940
              }
            )}
          >
            {tab.navControl !== SectionNames.ContactUs ? (
              <>
                <MainLinks
                  links={tab.links}
                  navControl={tab.navControl}
                  activeTab={activeTab}
                  animateClass={animateClass(tab.navControl)}
                  hoverClass={hoverClass()}
                  locale={locale}
                  onClick={() => onClick(tab.navControl)}
                  tabFocus={activeTab === tab.navControl}
                />

                {tab.featuredLinks && (
                  <FeaturedLinks
                    links={tab.featuredLinks}
                    heading={tab.featuredLinksHeading}
                    navControl={tab.navControl}
                    animateClass={animateClass(tab.navControl)}
                    activeTab={activeTab}
                    hoverClass={hoverClass()}
                    locale={locale}
                    onClick={() => onClick(tab.navControl)}
                    tabFocus={activeTab === tab.navControl}
                  />
                )}
              </>
            ) : (
              <ContactUsContent
                animateClass={animateClass}
                onClick={() => onClick(SectionNames.ContactUs)}
                hoverClass={hoverClass()}
                messageUsLinks={tab.links}
                helpCentreLinks={tab.featuredLinks}
                navControl={tab.navControl}
                activeTab={activeTab}
                contentActive={activeTab === SectionNames.ContactUs}
              />
            )}

            <SpotLight
              tab={tab.spotlight}
              navControl={tab.navControl}
              animateClass={animateClass(tab.navControl)}
              hoverClass={hoverClass()}
              locale={locale}
              newsletterPromotion={newsletterPromotion}
              activeTab={activeTab}
              tabFocus={activeTab === tab.navControl}
              onClick={() => onClick(tab.navControl)}
              windowHeight={windowHeight}
            />
          </div>

          <FeaturedImage
            featuredImage={tab.featuredImage}
            sectionName={tab.navControl}
            activeTab={activeTab}
            isLaptop={isLaptop}
            imageHeight={panelHeight}
          />
        </div>
      ))}
    </div>
  );
};

export default MegaNavPanelDesktop;

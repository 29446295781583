import useSWR from 'swr';
import NextLink from 'next/link';
import clsx from 'clsx';

import { useLocale, useTranslate, useMediaQuery } from '@hooks';
import { get } from '@utils/http';
import { breakpoints } from '@utils';
import { mapLocaleToSearchUri } from '@utils/mappers/uriMappers';
import { ContactInfo } from '@content/models/contactInfo';
import { supportHub, appbar } from '@microcopies';
import { MegaNavItem } from '@content/gql/graphql';

import { SectionNames } from './constants';

const ContactUsContent = ({
  animateClass,
  hoverClass,
  navControl,
  activeTab,
  contentActive,
  messageUsLinks,
  helpCentreLinks,
  onClick
}: {
  animateClass: (arg: string) => string;
  hoverClass: string;
  navControl: string;
  activeTab: string | null;
  contentActive: boolean;
  messageUsLinks: MegaNavItem['links'];
  helpCentreLinks: MegaNavItem['featuredLinks'];
  onClick: () => void;
}) => {
  const locale = useLocale();
  const isTabletLandscape = useMediaQuery(breakpoints.tabletLandscape);
  const translate = useTranslate(supportHub, (x) => x.supportHub);
  const translateAppBar = useTranslate(appbar, (x) => x.appbar);

  const { data: contactInfo } = useSWR(
    `/api/content/contactInfo/?locale=${locale}`,
    get<ContactInfo>()
  );
  const splitOpenTimes = contactInfo?.phoneNumbers[0].availability?.split('|');
  const phoneNumbersLength = contactInfo?.phoneNumbers?.length || 0;
  const openingTimesLength = splitOpenTimes?.length || 0;
  const messageUsLength = messageUsLinks.length + 1;
  const section1Length = phoneNumbersLength + openingTimesLength + 1;
  const sections1_2Length = section1Length + messageUsLength;

  const delay = activeTab === navControl ? 5 : 1;
  return (
    <div
      className={clsx(
        'flex flex-col mt-8 tabletLandscape:mt-0 tabletLandscape:max-h-48 gap-6 tablet:gap-12'
      )}
    >
      <div>
        <p
          className={`mb-2 tablet:mb-4 text-2xs text-hx-neutrals-60 ${animateClass(
            SectionNames.ContactUs
          )}`}
          style={{
            transitionDelay: `${1 * delay}0ms`
          }}
        >
          {translateAppBar((x) => x.siteSearch)}
        </p>
        <div
          className={`flex flex-wrap gap-1 ${animateClass(
            SectionNames.ContactUs
          )}`}
          style={{
            transitionDelay: `${2 * delay}0ms`
          }}
        >
          <a
            className={`text-sm  text-black  ${hoverClass}`}
            href={`/${locale}/${mapLocaleToSearchUri(locale)}/`}
            tabIndex={contentActive ? 0 : -1}
            onClick={onClick}
          >
            {translateAppBar((x) => x.findCruises)}
          </a>
        </div>
      </div>
      <div
        className={clsx(
          'flex flex-col tabletLandscape:flex-row gap-6 tablet:gap-12'
        )}
      >
        <div className="basis-0 grow-1">
          <p
            className={`mb-2 tablet:mb-4 text-2xs text-hx-neutrals-60 ${animateClass(
              SectionNames.ContactUs
            )}`}
            style={{
              transitionDelay: `${3 * delay}0ms`
            }}
          >
            {translate((x) => x.contactUs)}
          </p>
          <div className="mb-2 pt-[3px]">
            {contactInfo?.phoneNumbers?.map(
              ({ label, countryCode, phoneNumber }, i) => (
                <div
                  className={`flex flex-wrap gap-1 ${animateClass(
                    SectionNames.ContactUs
                  )}`}
                  key={label}
                  style={{
                    transitionDelay: `${(i + 3) * delay}0ms`
                  }}
                >
                  <p className="text-black ">{label}</p>
                  <a
                    className={`text-sm  text-black  ${hoverClass}`}
                    href={`tel:${countryCode ?? ''} ${phoneNumber}`}
                    tabIndex={contentActive ? 0 : -1}
                    onClick={onClick}
                  >
                    {countryCode} {phoneNumber}
                  </a>
                </div>
              )
            )}
          </div>
          {Boolean(contactInfo?.phoneNumbers?.length) &&
            splitOpenTimes &&
            splitOpenTimes.map((time, i) => {
              const phoneNumbersLength1 =
                contactInfo?.phoneNumbers?.length || 0;

              return (
                <p
                  className={`mb-1 text-black text-2xs ${animateClass(
                    SectionNames.ContactUs
                  )}`}
                  style={{
                    transitionDelay: `${
                      (i + 4 + phoneNumbersLength1) * delay
                    }0ms`
                  }}
                  key={i}
                >
                  {time}
                </p>
              );
            })}
        </div>

        <div className="basis-0 grow-1">
          <p
            className={`mb-2 tablet:mb-4 text-2xs delay-[280ms] tabletLandscape:delay-[0ms] text-hx-neutrals-60 ${animateClass(
              SectionNames.ContactUs
            )}`}
            style={{
              transitionDelay: isTabletLandscape
                ? `${2 * delay}0ms`
                : `${(2 + section1Length) * delay}0ms`
            }}
          >
            {translate((x) => x.messageUs)}
          </p>
          {messageUsLinks?.map(({ url, label }, i) => (
            <div
              key={url}
              className={`mb-3 ${animateClass(SectionNames.ContactUs)}`}
              style={{
                transitionDelay: isTabletLandscape
                  ? `${(i + 3) * delay}0ms`
                  : `${(i + 3 + section1Length) * delay}0ms`
              }}
            >
              <a
                className={`text-sm text-black ${hoverClass}`}
                href={url}
                tabIndex={contentActive ? 0 : -1}
                onClick={onClick}
              >
                {label}
              </a>
            </div>
          ))}
        </div>
        <div className="basis-0 grow-1">
          <p
            className={`mb-2 tablet:mb-4 text-2xs mobile:delay-[500ms] tabletLandscape:delay-[0ms] text-hx-neutrals-60 ${animateClass(
              SectionNames.ContactUs
            )}`}
            style={{
              transitionDelay: isTabletLandscape
                ? `${2 * delay}0ms`
                : `${(2 + sections1_2Length) * delay}0ms`
            }}
          >
            {translate((x) => x.helpCentre)}
          </p>
          {helpCentreLinks?.map((link, i) => (
            <div
              key={i}
              className={`${animateClass(SectionNames.ContactUs)}`}
              style={{
                transitionDelay: isTabletLandscape
                  ? `${(i + 3) * delay}0ms`
                  : `${(i + 3 + sections1_2Length) * delay}0ms`
              }}
            >
              <NextLink
                locale={locale}
                href={link.url}
                prefetch={false}
                className={` text-black text-sm ${hoverClass}`}
                data-testid={`nav-contact-${i}`}
                tabIndex={contentActive ? 0 : -1}
                onClick={onClick}
              >
                {link.label}
              </NextLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUsContent;

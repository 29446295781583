import React, {
  ReactNode,
  createContext,
  useRef,
  useState,
  useEffect
} from 'react';
import clsx from 'clsx';

import { isFeatureEnabled } from '@utils';

interface ContextType {
  wrapperElement: HTMLDivElement | null;
}

const WrapperContext = createContext({} as ContextType);

export interface PropsType {
  children?: ReactNode;
  className?: string;
  isBookingFlow?: boolean;
  showHeader?: boolean;
}

const Wrapper: React.FC<PropsType> = ({
  children,
  className,
  isBookingFlow = false,
  showHeader = true
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [contextObject, setContextObject] = useState<ContextType>({
    wrapperElement: null
  });
  const isRibbonEnabled = isFeatureEnabled('showHrgGroupBanner');

  useEffect(() => {
    setContextObject({
      wrapperElement: ref.current
    });
  }, []);

  return (
    <div
      data-testid="aurora-wrapper"
      className={clsx(
        'aurora-theme-hx',
        !showHeader || isBookingFlow
          ? '!pt-0'
          : [
              isRibbonEnabled
                ? 'pt-[104px] tablet:pt-[120px] tabletLandscape:pt-[152px]'
                : 'pt-[65px] tabletLandscape:pt-[92px]'
            ],
        className
      )}
      id="aurora-wrapper"
      ref={ref}
    >
      <WrapperContext.Provider value={contextObject}>
        {children}
      </WrapperContext.Provider>
    </div>
  );
};

export { Wrapper, WrapperContext };

import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useIsBookingFlow = (
  {
    includePaymentResponse,
    includeExpired,
    includeConfirmation
  }: {
    includePaymentResponse?: boolean;
    includeExpired?: boolean;
    includeConfirmation?: boolean;
  } = {
    includePaymentResponse: false,
    includeExpired: false,
    includeConfirmation: false
  }
) => {
  const router = useRouter();
  const isBookingFlow = useMemo(
    () =>
      router?.pathname.startsWith(`/cruises/[cruise]/booking`) &&
      (includePaymentResponse ||
        !router?.pathname.includes('paymentResponse')) &&
      (includePaymentResponse || !router?.pathname.includes('error')) &&
      (includeExpired || !router?.pathname.includes('expired')) &&
      (includeConfirmation || !router?.pathname.includes('confirmation')) &&
      !router?.pathname.includes('embeddedPaymentResponse'),

    [router?.isReady, router?.pathname]
  );
  return isBookingFlow;
};
